input[type='file'] {
  display: none;
}

.shellBox::-webkit-scrollbar {
  display: none;
}
.company-user-logo .MuiAvatar-root {
  width: 40px;
  height: 40px;
}
.company-user-logo img {
  object-fit: cover;
  width: auto !important;
  height: auto !important;
  max-width: 40px;
  max-height: 40px;
}
.switch-role-modal .focus-visible-only:focus-visible {
  outline: none !important;
}

.super-admin-chip {
  color: rgb(57 170 138);
  font-size: 12px;
  width: fit-content;
  display: block;
  border-radius: 1em;
}
.main-loader {
  height: 100vh;
  width: 100vw;
  padding-top: 15%;
  background-color: #f9f3f3;
}
.custom-notif {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 550px !important;
  height: 650px !important;
}
.notification {
  width: 100%;
  min-height: 50px;
  height: auto;
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #feffe9;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
}
