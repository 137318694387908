.colortext {
    
    font-size: 0.94em;
    text-align: left;
    font-weight: bold;
    color: #7c0e6f;
}
.Statustext {
    
    font-size: 0.94em;
    text-align: left;
    color: #ff5263;
}
.textstyle {
    font-weight: bold;
    font-size: 12px;
    
}
.tss-1akey0g-MUIDataTableHeadCell-data {
    
    font-size: 0.85em;
    font-weight: bold;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
    background-color: #f5f5f5 !important;
}
.tss-58la38-MUIDataTable-paper {
    border: 0.5px solid #e6e8f0 !important;
    border-radius: 0.5em !important;
}
.custom-modal1 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 900px;
    height: 600px;
}
.custom-modal2 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 700px;
    height: auto;
}
.custom-modal5 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1200px;
    height: 800px;
}
.custom-modalPayrollSett {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1300px;
    height: 660px;
}
.modalPayrollSettelmentView {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1300px;
    height: auto;
}
.custom-modal6 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 874px;
    height: 700px;
}
.custom-modalTaxView {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 874px;
    height: auto;
}

.custom-modal7 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 874px;
    height: auto;
}
.custom-modalSetsalview {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: auto;
}
.custom-modal3 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 900px;
    height: 700px;
}
.custom-modal4 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 900px;
    height: 600px;
}
.border-box {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    padding: 1em;
}
.border-boxeditloan {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    padding: 1em;
    height: 28em;
}
.approvedby-text {
    
    font-weight: "bold" !important;
    color: black;
    font-size: 1em !important;
}
.leave-box {
    background-color: #f5f5fa66;
    height: 186.5px;
    border-radius: 0.6em;
    padding: 0.7em;
    display: flex;
    margin-bottom: 1em;
}
.reject-box {
    padding: 3em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 35.9375em;
    height: 21.25em;
}
.input-box3 {
    /* border: 1px solid #0000001A; */
    border-radius: 4px;
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-box-PA {
    width: 8em;
    height: 2.5em;
    background-color: #f5f5fa;
    border-radius: 0.25em;
    margin-top: 0em;
    border-color: #ffffff;
}
.input-box-small {
    width: 2em;
    height: 2.5em;
    background-color: #f5f5fa;
    border-radius: 0.25em;
    margin-top: 0em;
    margin-left: 0.1em;
    border-color: #ffffff;
}
.export-button1 {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: #7c0e6f !important;
    padding: 6px 12px;
    border: 1px dashed !important;
    /* line-height: 1.5; */
    background-color: #7c0e6f1a !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    border-radius: 0.5em;
}
.input-box-title10 {
    flex: 1;
    color: #00000099;
    margin-left: 0.8em !important;
}
.select {
    background-color: white;
    text-align: end;
}
.border-boxHRAdmin {
    background-color: white;
    /* border-radius: 0.8em;
    border: 1px solid #0000001f; */
}
.custom-modalReq {
    padding: 2em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: auto;
}
.custom-OvertimeModal1 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 750px;
    height: auto;
}
.custom-OvertimeModal2 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 450px;
    height: auto;
}
.offcycle-empname p:nth-child(2){
    font-size: 12px !important;
}
.offcycle-empname p{
    font-weight: normal !important;
}
.muiltr-1cszki3 {
    margin-left: 0 !important;
}