.settlementList {
  overflow: auto;
  overflow-x: auto;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
  background-color: white;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #e6e8f0; /* color of the scroll thumb */
  border-radius: 8px; /* roundness of the scroll thumb */
  height: 1px;
  scrollbar-width: thin;
}

.settlementList .MuiDataGrid-virtualScroller {
  height: 60vh !important;
  overflow-x: auto;
  min-height: 100%;
  overflow-y: auto !important;
}

.MuiButton-root-168 {
  font-size: 14px !important;
}
