.notification-table .MuiDataGrid-columnHeaders {
    line-height: 0 !important;
}
.custom-notif {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 550px !important;
    height: 650px !important;
}
.welcome-text {
    font: normal normal bold 20px  !important;
    color: #8181a5;
}
.welcome-text2 {
    font: normal normal bold 20px !important;
    color: #263971 !important;
    text-transform: capitalize;
}
