.top {
    margin-left: 1em !important ;
    margin-top: 1em;
    flex: 1;
}
.left {
    justify-content: left;
    margin-top: 2em;
}
.center {
    justify-content: center;
    margin-top: 2em;
}

.comp__modal {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: auto;
    height: "auto";
}
.comp__border {
    border: 1px solid var(--default-misc-other-divider-000000-12-);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #0000001f;
    border-radius: 10px;
    opacity: 1;
    width: auto;
    height: auto;
    padding: 1em;
}
.modal__heading {
    font-weight: bold;
}
.cb__font {
}
.cb__font__active {
    color: #7c0e6f !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.cb__font__left {
    color: #0000008a !important;
    font-size: 12px !important;
}
.cb__font__head {
    color: black !important;
    font-weight: bold !important;
}
.cb__font__right {
    color: black !important;
    font-size: 12px !important;
    font-weight: bold !important;
}
.warning__ {
    font-size: 12px !important;
    font-weight: bold !important;
    color: #ff5263 !important;
}
.center-box {
    align-items: center;
    justify-items: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 8px 32px #8f95b226;
    padding: 0.5em;
    min-height: 435px;
}
.tile__list {
    background: var(--default-050-fafafa) 0% 0% no-repeat padding-box;
    background: #fafafa 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5em;
    width: 100%;
    height: auto;
    padding: 0.3em;
}
.tile__list__check {
    background: var(--default-050-fafafa) 0% 0% no-repeat padding-box;
    background: #fafafa 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5em;
    width: 100%;
    height: auto;
}
.dotted-box-center {
    align-items: center;
    justify-items: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 8px 32px #8f95b226;
    padding: 0.5em;
    border: 2px dashed #711f6b;

    justify-content: center;
    margin-bottom: 0.5em;
}
.dotted-box {
    padding: 1.3em;
    border-radius: 4em;
    border: 2px dashed #711f6b;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 0.5em;
    width: 108px;
    height: 108px;
}
.top-right {
    margin-top: 1em;
    margin-right: 2em;
    flex-wrap: nowrap !important;
    flex-direction: row;
}
.cb {
    color: #081735;
    font-size: 1em !important;
    font-weight: bold !important;
    text-transform: capitalize;
}
.uae {
    font-size: 0.8em !important;
    color: #8181a5;
}
.mobileStepper {
    color: "red";
}
.delete-color {
    color: #ff5263;
    font-weight: 22;
}
.edit-color {
    color: #21d8a5;
    font-weight: 22;
}
.basic-add-modal-box {
    padding: 3em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 525px;
    height: 24rem;
}
.add-modal-box {
    padding: 3em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 525px;
    height: 635px;
}
.Toastify__toast--error {
    border: 1px solid #eb5757;
    /* border-radius: 50px !important; */
    background: #ffedef !important;
}
.Toastify__toast--error::after {
    content: url("../../assets/svg/close-toast.svg");
    position: absolute;
    color: #333333;
    font-size: 10px;
    font-weight: 700;
    right: 15px;
    padding-top: 14px !important;
    margin-left: 15px !important;
}
.Toastify__toast--error::before {
    /* content: url("../assets/images/svg/errorToast.svg"); */
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}
.Toastify__toast--success {
    border: 1px solid #21d8a5 !important;
    /* border-radius: 50px !important; */
    background: #f0f9fa !important;
}
.Toastify__toast--success::before {
    /* content: url("../../../assets/close-toast.svg"); */
    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}
.Toastify__toast--success::after {
    content: url("../../assets/svg/close-toast.svg");
    position: absolute;
    color: #333333;
    font-size: 10px;
    font-weight: 700;
    right: 15px;
    padding-top: 14px !important;
    margin-left: 25px !important;
}
.Toastify__toast--warning {
    border: 1px solid #e78326 !important;
    /* border-radius: 50px !important; */
    background: #f8e4d2 !important;
}
.Toastify__toast--warning::before {
    /* content: url("../assets/images/svg/warnToast.svg"); */

    position: relative;
    z-index: 100000;
    left: 12px;
    top: 6px;
}
.Toastify__toast--warning::after {
    content: url("../../assets/svg/close-toast.svg");

    /* content : url('../assets/images/svg/closeToast.svg'); */
    /* position: absolute; */
    color: #e78326;
    font-size: 10px;
    font-weight: 700;
    right: 15px;
    padding-top: 14px !important;
    margin-left: 15px !important;
}
.Toastify__toast-container {
    width: fit-content !important;
}
.Toastify__toast-body {
    color: #444c63;
    font-size: 16px;
    padding-left: 20px;
    line-height: 20px;
    padding: 0px;
    width: 100%;
    font-weight: 400;
    margin-left: 15px !important;
    margin-right: 30px !important;
}
.Toastify__toast > button > svg {
    display: none;
}
