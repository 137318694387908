.pgrid-container {
    margin-top: 0.5em !important;
    /* margin-left: 0.5em; */
}
.big-title {
    font-size: 1.1em !important;
    
    font-weight: bold !important;
    color: #1c1d21;
    margin-bottom: 0.5em !important;
}
.links {
    color: #8181a5;
    font-size: 0.8em !important;
    
    font-weight: bold !important;
    letter-spacing: 0px;
}
.selected-links {
    color: #7c0e6f !important;
    font-size: 0.8em !important;
    
    font-weight: bold !important;
    letter-spacing: 0px;
}
.row {
    margin-bottom: 1.5em !important;
    padding-right: 1.5em !important;
    flex-wrap: nowrap !important;
}
.second-row {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
}
.payroll-title {
    color: #1c1d21;
    font: normal normal bold 0.95em !important;
    margin-bottom: 1em !important;
    flex: 1;
}
.payroll-title1 {
    color: #1c1d21;
    font: normal normal bold 0.95em !important;
    flex: 1;
}
.third-row {
    margin-top: 1.5em !important;
    padding-right: 1.5em !important;
    flex-wrap: nowrap !important;
}
.dept-salary {
    margin-left: 1.5em !important;
    margin-right: 1.5em !important;
}
.small-text {
    color: #8f95b2;
    font: 0.8em !important;
    align-self: end;
}
.bold-text {
    color: #081735;
    font: normal normal bold 1.6em !important;
}
.css-1laqsz7-MuiInputAdornment-root {
    margin-left: 0 !important;
}
.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
}
.border-boxPayRoll {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
}
.selectAll-button {
    color: #66d4a8 !important;
    font-size: 0.8125em !important;
    
    font-weight: bold !important;
    letter-spacing: 0px;
    border: 1px solid #66d4a8 !important;
}
.custom-modalDelete {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 400px;
    height: 400px;
}
.custom-modalpayrollcycle {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 550px;
    height: 700px;
}
.cancel-buttonPayrol {
    font-size: 0.8em !important;
    font-weight: bold;
    color: #ff5263 !important;
    border-radius: 5px !important;
    background-color: #ffedef !important;
    text-transform: none !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;
    border-radius: 0.5em !important;
    height: 2.8em;
    width: 10.25em;
}
.textfield-Gov {
    font-size: 1em !important;
    
    font-weight: bold;
}
.EmpButtons {
    font-size: 0.75em !important;
    
    color: #8f95b2;
    background-color: #f2f2f6 !important;
    height: 1.625em;
    border-radius: 0.25em;
}
.input-boxNew {
    border: 1px solid #0000001a;
    border-radius: 4px;
    padding: 10px 6px;
    margin-top: 1em;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}
.input-boxTop {
    border: 1px solid #0000001a;
    border-radius: 4px;

    margin-top: 1em;
    height: 4.375em;
}
.salarytext {
    font-size: 0.625em !important;
    
    color: #8f95b2;
}
.salarytext2 {
    font-size: 0.75em !important;
    
    color: #081735;
}
.Button-new1 {
    color: #8181a5 !important;
    background-color: #f2f2f6 !important;
    font-size: 0.75em !important;
    border-radius: 1em !important;
    font-weight: bold !important;
    height: 1.625em !important;
}
.Box1 {
    background-color: #f5f5fa !important;
    border-radius: 0.625em !important;
    padding: 1em;
}
.editbox {
    border: 1px solid #e6e8ef;
    color: #21d8a5;
    width: 1.8125em;
    height: 1.8125em;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 0.375em;
    background-color: #ffffff;
    cursor: "pointer";
}
.text2 {
    font-size: 0.75em !important;
    
    color: #8f95b2;
}
.text3 {
    font-size: 0.875em !important;
    
    color: #081735;
    font-weight: bold;
}
.Button-new2 {
    color: #21d8a5 !important;
    text-transform: capitalize !important;
    background-color: #21d8a51a !important;
    
    border-radius: 0.375em !important;
    height: 1.625em !important;
}
.custom-modalAssignGovTax {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 800px;
    height: 730px;
}
.custom-modalGovTax {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: 700px;
}
.box1 {
    margin-top: 1em;
    margin-left: 1em;
    margin-right: 1em;
}
.buttonRecurr {
    color: #ffffff !important;
    background-color: #7c0e6f !important;
    height: 2.875em !important;
    width: 19em !important;
    border-radius: 6px !important;
    
    font-size: 0.875em !important;
    text-transform: capitalize !important;
    padding: 6px 8px !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;
    box-shadow: 0px 3px 3px  #00000040;

    /* color: "#FFFFFF",
    backgroundColor: "#7C0E6F",
    height: "2.875em",
    width: "14.25em",
    borderRadius: "6px",
    font: "normal normal bold 14px/20px Lato",
    fontSize: "0.875em",
    letterSpacing: "0px",
    textTransform: "none",
    boxShadow: "0px 3px 3px #00000040", */
    


}
.custom-modaleditloan {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: 600px;
}
.editbox1 {
    background-color: #fbfbfd;
    height: 6.875em;
    border-radius: 0.5em;
    padding: 1em;
}
.boxDate {
    height: 2.5em !important;
    width: 10em !important;
    border: 1px solid #7c0e6f !important;
}
.custom-modaleditloan2 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: 700px;
}
.custom-modalAddGovTax {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 650px;
    height: 550px;
}
.border-icon-boxpayroll {
    width: 1.813em;
    height: 1.813em;
    border-radius: 0.375em;
    border: 1px solid #e6e8ef;
    margin-right: 0.5em;
    padding-top: 0.21em;
    padding-left: 0.3em;
}
.settlementbox {
    background-color: #fbfbfd;
    height: 6.125em;
    border-radius: 0.625em;
    padding: 1.5em;
}
.payrollsetttitle {
    
    color: #8181a5;
    font-size: 0.875em;
}
.payrollsetttitle2 {
    
    color: #081735;
    font-size: 1.25em;
    font-weight: bold !important;
}
.settlementbox2 {
    background-color: #e0f9ee !important;
    height: 3.5em !important;
    width: 3.5em !important;
    border-radius: 0.625em !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}
.settlementbox3 {
    background-color: #7c0e6f1a !important;
    height: 3.5em !important;
    width: 3.5em !important;
    border-radius: 0.625em !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}
.settlementbox4 {
    background-color: #fff5d0 !important;
    height: 3.5em !important;
    width: 3.5em !important;
    border-radius: 0.625em !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}
.settlementbox5 {
    background-color: #ffedef !important;
    height: 3.5em !important;
    width: 3.5em !important;
    border-radius: 0.625em !important;
    align-items: center !important;
    justify-content: center !important;
    display: flex !important;
}
.custom-generatesett {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: 750px;
}
.custom-OvertimeModal {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 750px;
    height: 700px;
}
.reset-button1 {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: #fd5162 !important;
    padding: 6px 12px;
    border: 1px dashed !important;
    background-color: #ff52631a !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-radius: 0.5em;
    height: 3.1em;
}
.sub-button1 {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: #ffffff !important;
    padding: 6px 12px;
    background-color: #7c0e6f !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-radius: 0.25em;
    height: 3.1em;
}
.selectNew {
    background-color: white;
    text-align: end;
}
