.colortext {
  font-size: 0.94em;
  text-align: left;
  font-weight: bold;
  color: #7c0e6f;
}
.Statustext {
  font-size: 0.94em;
  text-align: left;
  color: #ff5263;
}
.textstyle {
  font-weight: bold;
  font-size: 12px;
}
.tss-1akey0g-MUIDataTableHeadCell-data {
  font-size: 0.85em;
  font-weight: bold;
}
.tss-58la38-MUIDataTable-paper {
  border-radius: 0.5em !important;
}
.custom-modal1 {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 900px;
  height: 600px;
}
.custom-modal2 {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 700px;
  height: 400px;
}
.custom-modal3 {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 900px;
  height: 700px;
}
.custom-modal4 {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 900px;
  height: 600px;
}
.border-box {
  background-color: white;
  border-radius: 0.8em;
  border: 1px solid #0000001f;
  padding: 1em;
}
.approvedby-text {
  font-weight: 'bold' !important;
  color: black;
  font-size: 1em !important;
}
.leave-box {
  background-color: #f5f5fa66;
  height: 186.5px;
  border-radius: 0.6em;
  padding: 0.7em;
  display: flex;
  margin-bottom: 1em;
}
.reject-box {
  padding: 3em;
  border-radius: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 35.9375em;
  height: 21.25em;
}
.input-box3 {
  /* border: 1px solid #0000001A; */
  border-radius: 4px;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.export-buttonn {
  padding: 6px 12px;
  border: 1px dashed !important;
  /* line-height: 1.5; */

  padding-left: 2.5em !important;
  padding-right: 2.5em !important;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  border-radius: 0.5em;
  /* margin-right:1.5em;
    margin-left:1.5em; */
  height: 2.875em;
  width: 14.25em;

  background-color: #7c0e6f;
  box-shadow: 0px 3px 3px #00000040;
  border-radius: 8px;
  opacity: 1;
}
.input-box-title10 {
  flex: 1;
  color: #00000099;
  margin-left: 0.8em !important;
}
.select {
  background-color: white;
  text-align: end;
}
.border-boxHRAdmin {
  background-color: white;
  /* border-radius: 0.8em;
  border: 1px solid #0000001f; */
}
.announce {
  text-align: left;
  font: normal normal bold 24px/29px;
  letter-spacing: 0px;
  color: #0c2372;
  opacity: 1;
}
.rdw-fontsize-wrapper,
.rdw-dropdown-wrapper .rdw-fontsize-dropdown,
.rdw-block-wrapper,
.rdw-fontfamily-wrapper {
  direction: ltr !important;
  text-align: left !important;
}