.top {
    margin-left: 1em !important;
    flex: 1;
}

.center {
    justify-content: center;
    margin-top: 2em;
}

.center-box {
    align-items: center;
    justify-items: center;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 8px 32px #8f95b226;
    padding: 0.5em;
    display: flex;
    flex-direction: column;
}

.btn {
    background: #21d8a51a 0% 0% no-repeat padding-box;
    border: 1px dashed #66d4a8;
    border-radius: 8px;
    opacity: 1;
    width: 20em;
    height: 46px;
}

.custom-modal5 {
    width: 20em;
    height: 14em;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 16px;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}

.dotted-box {
    padding: 1.3em;
    border-radius: 4em;
    border: 2px dashed #711f6b;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 0.5em;
    width: 108px;
    height: 108px;
}

.cb {
    color: #081735;
    
    font-size: 1em !important;
    font-weight: bold !important;
}

.uae {
    
    font-size: 0.8em !important;
    color: #8181a5;
}

.mobileStepper {
    color: "red";
}
.mainCard {
    min-height: "30vh";
    border-radius: "1em";
    padding: 20;
    background-color: "white";
}
.selectedlinks {
    color: #7c0e6f;
    font-size: 0.8em !important;
    
    font-weight: bold !important;
    letter-spacing: 0px;
    flex: 1;
}

.links {
    color: #8181a5;
    font-size: 0.8em !important;
    
    font-weight: bold !important;
    letter-spacing: 0px;
    flex: 1;
}
