.company-conf-container {
  background-color: #f5f5fa;
  box-sizing: border-box;
}

.br-company-conf {
  border-radius: 10px;
}

.company-conf-side-menu {
  width: 270px;
}

.company-conf-top-nav {
  height: 55px;
}

.company-conf-children {
  width: -webkit-fill-available;
}

.sub-item {
  color: #7c0e6f;
}

.table-v2 th {
  padding: 0.75rem 1rem !important;
  border-bottom: none;
}

.table-v2 th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-v2 th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-icon {
  font-size: 18px;
}

.add-configuration-section-card {
  height: 452px;
}

.add-configuration-section-card-container {
  height: 507px;
  max-width: 1115px;
  display: flex;
  gap: 0.9rem;
}

.added-list-section {
  height: 383px;
}

.side-menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
