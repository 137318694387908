.custom-modalPRO {
  padding: 1.5em 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 750px;
}
.custom-modalPRO2 {
  padding: 1.5em 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 500px;
}
.custom-modalPRO3 {
  padding: 1.5em 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 1050px;
}
.input-boxPRO {
  border: 1px solid #0000001a;
  border-radius: 4px;
  padding: 10px 6px;
  /* margin-top:1em; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-boxNew {
  border: 1px solid #0000001a;
  border-radius: 4px;
  height: 3em;
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-boxPRO {
  background-color: white;
  border-radius: 0.8em;
}

.card {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-direction: column;
  border: 2px solid rgb(223, 223, 223);
  border-radius: 12px;
  height: 151px;
  transition: all 0.3s ease-in-out;
}

.card:hover {
  box-shadow:
    0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.card-details {
  display: flex;
  align-items: center;
  gap: 0.25rem 0.5rem;
  padding: 0.9rem 0.4rem 0 0.4rem;
}

.img {
  border-radius: 100%;
  object-fit: cover;
}

.card-details .info {
  display: flex;
  flex-direction: column;
  gap: 0.2rem 0;
}

.card-details .id {
  font-size: 12px;
  color: #d1d0d0;
}

.card-details .position {
  font-size: 12px;
  color: #b9b9b9;
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: #eeeeeea8;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  height: 45px;
}

.card-footer .actions {
  display: flex;
  height: 49px;
  align-items: center;
  gap: 0 0.5rem;
}

.card-footer p {
  font-size: 14px;
}

.card-footer .actions ._btn {
  display: flex;
  width: 29px !important;
  height: 29px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e0e0e0;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.card-footer .actions ._btn .view {
  color: theme('colors.primary');
}

.MuiFormLabel-root-1885 {
  font-size: 13px !important;
}

.muiltr-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 13px !important;
}

.MuiFormLabel-root-273 {
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  margin-left: 0.8em !important;
}
.MuiFormLabel-root-273.MuiFormLabel-focused-275 {
  color: #7c0e6f !important;
}
.muiltr-18e57h7-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input {
  margin-left: 0.8em !important;
}

.input-dropdown-autocompleted,
label {
  font-size: 13px !important;
  color: '#E6E8F0' !important;
  margin-left: 5px !important;
}

.MuiAutocomplete-input {
  font-size: 12px !important;
}
.MuiSelect-select-1762.MuiSelect-select-1762 {
  padding-right: 8px !important;
}

@media (direction: ltr) {
  .MuiAutocomplete-input {
    text-align: left !important;
  }
}

@media (direction: rtl) {
  .muirtl-18e57h7-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    text-align: right !important;
  }
}
