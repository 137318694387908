.tab-header_perfomance {
     border-radius: 16px 16px 0px 0px; 
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px hsla(220, 33%, 98%, 0.161);
    padding-top: 1em;
    
}

.tab-header_perfomance2 {
    
    border-radius: 16px;
   background: white 0% 0% no-repeat padding-box;
   box-shadow: 0px 1px 2px hsla(220, 33%, 98%, 0.161);
}

.export-button_perfomance {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: black !important;
    padding: 6px 12px;
    border: 0.1px solid black !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-radius: 0.5em;

    height: 3.1em;
}

.bulk-import-button-perfomance {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: #4caf50 !important;
    padding: 6px 12px;
    border: 0.5px dashed #4caf50 !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-radius: 0.5em;

    height: 3.1em;
}
.configure__btn {
    width: auto;
    height: 34px;
    padding: 1.4em;
    color: white !important;
    background-color: #7c0e6f !important;
    box-shadow: 0px 5px 6px #0000004d;
    border-radius: 4px;
    opacity: 1;
}

.table__data__perfomance .MuiTableHead-root {
    margin: 0;
    color: rgba(0, 0, 0, 0.87);
    
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

/* Adjust the border-radius value to control the amount of curve */

/* .table__perfomance__assignedKpi .MuiDataGrid-cell {
    min-width: auto !important;
    max-width: auto !important;
} */
