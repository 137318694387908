.selectedlinks {
  color: #7c0e6f;
  font-size: 0.8em !important;
  font-family: !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  flex: 1;
}
.links {
  color: #8181a5;
  font-size: 0.8em !important;
  font-family: !important;
  font-weight: bold !important;
  letter-spacing: 0px;
  flex: 1;
}
.card-title {
  font-size: 0.9em !important;
  font-family: !important;
  font-weight: bold !important;
  color: #8181a5;
}
.second-card-title {
  font-size: 0.9em !important;
  font-family: !important;
  font-weight: bold !important;
  color: #7c0e6f;
}
.center-box {
  box-shadow: none;
  width: 300px;
  align-self: center;
  justify-self: center;
  text-align: center;
}
.box-title {
  font-size: 0.9em !important;
  font-family: !important;
  font-weight: bold !important;
  color: #081735;
}
.box-content {
  font-size: 0.8em !important;
  font-family: !important;
  color: #8f95b2;
  margin-bottom: 1em !important;
}
.box-container {
  flex-wrap: nowrap !important;
}
.label {
  background-color: #f5f5fa66;
  margin-top: 1em;
  margin-left: 0.05em !important;
  color: #92929d !important;
  margin-right: 0 !important;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 1em !important;
  font-family: !important;
  font-weight: bold !important;
}
.cancel-button {
  font-size: 12px !important;
  font-weight: bold;
  color: #ff5263 !important;
  border-radius: 8px !important;
  background-color: #ffedef !important;
  text-transform: uppercase !important;
  
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
  padding-top: 0.1em !important;
  padding-bottom: 0.1em !important;
  height: 3.5em !important;
}
.MuiFormControl-root {
  margin: 0;
}
.custom-modalOrg {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 900px;
  height: 650px;
}
.profileBox {
  background-color: #f5f5fa66;
  border-radius: 0.6em;
  padding: 0.7em;
  display: 'flex';
  width: 16.5em;
  margin-bottom: 1em;
}
.border-boxOrg {
  background-color: white;
  border-radius: 0.8em;
  border: 1px solid #0000001f;
  /* padding: 1em; */
}

.tss-tlx3x1-MUIDataTableToolbar-root {
    max-width: 500px !important;
}