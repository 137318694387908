.border-boxAssets {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
}
.Asset-modal {
    padding: 3em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 525px;
    height: 416px;
}
.Asset-modal1 {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 818px;
    height: auto;
}
.Asset-modal2 {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 750px;
    height: 500px;
}
.cancel-button1 {
    font-size: 0.8em !important;
    font-weight: bold;
    color: #ff5263 !important;
    border-radius: 5px !important;
    background-color: #ffedef !important;
    text-transform: none !important;
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;
    border-radius: 0.5em !important;
    height: 2.625em;
    width: 10.25em;
}
.input-box-date {
    border: 1px solid #00000041;
    border-radius: 4px;
    padding: 14px 6px;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.input-box-titleAsset {
    flex: 1;
    color: #00000099;
    font: normal normal normal 1em ;
    font-size: 0.9em !important;
    margin-left: 0.8em !important;
}
.input-boxAsset {
    border: 1px solid #00000041;
    border-radius: 4px;
    padding: 14px 6px;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.border-boxAssignAsset {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    padding: 1em;
    height: 30em;
    overflow-y: auto;
}
.border-boxAssignAsset::-webkit-scrollbar {
    display: none;
}
