.forbidden-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
}

.forbidden-content {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.forbidden-heading {
    font-size: 36px;
    color: #d9534f;
    /* Red color for the heading */
    margin-bottom: 10px;
}

.forbidden-message {
    font-size: 18px;
    color: #333;
}