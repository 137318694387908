.acceptBox {
  color: #268e03f1 !important;
  height: 2em !important;
  width: auto !important;
  background-color: #d1e28d1a !important;
  text-transform: capitalize !important;
  font-size: 0.89em !important;
  font-weight: bold !important;
}
.scheduledBox {
  color: #e70a0af1 !important;
  height: 2em !important;
  width: auto !important;
  background-color: #cb90901a !important;
  text-transform: capitalize !important;
  font-size: 0.89em !important;
  font-weight: bold !important;
}
.custom-modaltrainingCreate {
  padding: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
}
.custom-modaltrainingCreate2 {
  padding: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
}
.completeBox {
  color: #1006a6f1 !important;
  height: 2em !important;
  width: auto !important;
  background-color: #d1e28d1a !important;
  text-transform: capitalize !important;
  font-size: 0.89em !important;
  font-weight: bold !important;
}
.trainingbox-2 {
  border: 1px solid #dbd9d9 !important;
  border-radius: 6px;
  overflow-y: auto !important;
  padding: 1em;
}
.trainingbox-3 {
  border: 1px solid #dbd9d9 !important;
  border-radius: 6px;
  padding: 1em;
}
.trainingEmpbox {
  margin-top: 0.5em !important;
}
.trainingtrainerbox {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  margin-top: 0.5em !important;
}
.BoxBig {
  border-radius: 16px 16px 0px 0px;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px hsla(220, 33%, 98%, 0.161);
  padding-top: 1em;
}
.cancelBox {
  color: #d40404f1 !important;
  height: 2em !important;
  width: auto !important;
  background-color: #d1e28d1a !important;
  text-transform: capitalize !important;
  font-size: 0.89em !important;
  font-weight: bold !important;
}
.completeBox {
  color: #455fbdf1 !important;
  height: 2em !important;
  width: auto !important;
  background-color: #d1e28d1a !important;
  text-transform: capitalize !important;
  font-size: 0.89em !important;
  font-weight: bold !important;
}
.emppofileBox {
  height: 70px !important;
  background-color: #f5f5fa66 !important;
  border-radius: 0.6em !important;
  padding: 0.7em !important;
  margin-bottom: 0.8em !important;
  cursor: 'pointer' !important;
}
.title-1 {
  font-size: 0.9em !important;
  font-weight: bold !important;
}
.title-2 {
  font-size: 0.7em !important;
  font-weight: bold !important;
  color: #8181a5 !important;
}
.error-box {
  color: #d41104 !important;
  font-size: 0.7em !important;
  height: 50px !important;
  padding: 10px !important;
  background-color: #ffedef;
}
.custom-modalTraining {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 900px;
  height: 670px !important;
}
.custom-modalTrainingComplted {
  padding: 3em;
  padding-top: 2em;
  border-radius: 1em;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 1100px;
  max-height: 700px;
}
.Trbox-2 {
  border: 1px solid #dbd9d9 !important;
  max-height: 500px;
  border-radius: 6px;
  padding: 1em;
  overflow-y: auto;
}

.mark_as_complete_table .MuiDataGrid-cell--withRenderer {
  min-width: 237.4px;
  max-width: 236.4px;
  min-height: 52px;
  max-height: 52px;
}
.customSelecet-Box {
  border: 1px solid #0000001a;
  border-radius: 4px;
  padding: 10px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customSelect-text {
  flex: 1;
  color: #00000099;
  font: normal normal normal 1em;
  font-size: 0.9em;
  margin-left: 0.5em !important;
}
.formControl {
  width: 140px;
  box-shadow: none;
}
.grid-style {
  height: 70px;
}
.grid-style2 {
  height: 70px;
  margin-top: 1em !important;
}
.textdesign {
  color: #7c0e6f;

  font-size: 0.8em;
  font-weight: bold;
}
.customSelecet-Box2 {
  height: 54px;
  border: 1px solid #0000001a;
  border-radius: 4px;
  padding: 10px 12px;
  /* margin-top: 1em; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-dropdown-autocompleted,
label {
  margin-left: 0px !important;
}
