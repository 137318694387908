.border-boxEmpData {
    background-color: white;
    /* border-radius: 0.5em;
    border: 1px solid #0000001f; */
}
.customeModal-Attendance {
    padding: 1.5em 3em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 800px;
}
.customeModal-EditAttendance {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 600px;
    height: 420px;
}
.custom-modalSwapshift {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 800px;
    height: auto;
}
.customeModal-leaveAllotment {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 459px;
    height: 500px;
}
.shadowBox {
    background-color: #f5f5fa66;
    border-radius: 4px;
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shadowBox2 {
    background-color: #f5f5fa66;
    border-radius: 4px;
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.cancel-button1 {
    font-size: 0.8em !important;
    font-weight: bold;
    color: #ff5263 !important;
    border-radius: 5px !important;
    background-color: #ffedef !important;
    text-transform: none !important;
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;
    border-radius: 0.5em !important;
    height: 2.625em;
    width: 10.25em;
}
.csvDownload-button {
    color: #21d8a5;
    background-color: #21d8a51a;
    border: 1px dashed #21d8a5;
    height: 2.875em;
    width: 21em;
    border-radius: 8px;
    font-size: 0.875em;
    text-transform: capitalize;
    padding: 6px 12px;
    padding-left: 2.5em;
    padding-right: 2.5em;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}
.border-boxEmpData2 {
    background-color: white;
    border-radius: 0.5em;
    border: 1px solid #0000001f;
    height: 35.5625em;
    align-items: center;
    justify-content: center;
    display: flex;
}
.border-boxMarkAtt {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    padding: 1em;
    height: 30em;
}
.calendar-box-emp .MuiInputAdornment-positionEnd {
    margin: auto;
    padding-left: 4px;
}
.bulkimport-downloadBtn {
    color: #21d8a5 !important;
    background-color: "#21D8A51A" !important;
    border: 1px dashed #21d8a5 !important;
    height: 2.875em !important;
    width: 21em !important;
    border-radius: 0.25em !important;
    font-size: 0.875em !important;
    text-transform: capitalize !important;
    padding: 6px !important;
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;
}
