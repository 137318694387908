.menu-item-text {
    color: #8181a5;
    font-size: 0.9em !important;
}
.menu-item-selected-text {
    color: #7c0e6f;
    font-size: 0.9em !important;
    font-weight: bold;
}
.grid-container {
    margin-top: 1.5em !important;
    margin-left: 0.5em;
}
.container {
    flex-wrap: nowrap !important;
}

.dash-row {
    margin-bottom: 0.8em !important;
    flex-wrap: nowrap !important;
    padding-right: 0;
}
.no-wrap {
    flex-wrap: nowrap !important;
}
.graph-row {
    margin-bottom: 0.8em !important;
    flex-wrap: nowrap !important;
    padding-right: 0;

    /* max-width: 90% !important; */
    /* max-height: 300px; */
}

.row-item {
    /* margin-left: 1.5em !important; */
    height: 25.063rem !important;
}

.MuiContainer-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.custom-text {
    color: #8f95b2;
    /* font-weight: 300; */
}
.welcome-text {
    font: normal normal bold 20px  !important;
    color: #8181a5;
}
.welcome-text2 {
    font: normal normal bold 20px !important;
    color: #263971 !important;
    text-transform: capitalize;
}
.welcome-text3 {
    font: normal normal bold 20px !important;
    color: #8181a5 !important;
    flex: 1.5;
}

.top-text {
    width: 299px !important;
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}
.title {
    color: #1c1d21;
    margin-top: 0.8em !important;
    margin-bottom: 1.2em !important;
    font: normal normal bold 1em !important;
}
.title2 {
    color: #1c1d21;
    margin-top: 0.2em !important;
    margin-bottom: 1.2em !important;
    font: normal normal bold 1em !important;
}
.title-text {
    color: #1c1d21 !important;
    font-size: 1em !important;
    font-weight: bold !important;
    margin-bottom: 0.8em !important;
    width: 100%;
    flex: 1;
    display: flex;
}
.title-text1 {
    color: #1c1d21 !important;
    font-size: 1em !important;
    font-weight: bold !important;
    width: 100%;
    flex: 1;
    display: flex;
}
.box {
    background: #e6e8f0;
    border-radius: 12px;
    padding: 0.6em;
    /* margin-left:'22px' */
    display: flex;
}
.top-right {
    height: 2.8em !important;
    flex-wrap: nowrap !important;
}
.icon {
}
.css-sox5kk-MuiBackdrop-root {
    background-color: rgb(0, 0, 0, 0) !important;
}
.rdw-editor-wrapper {
    border: 0.5px solid lightgray;
    /* height:5em */
}
.rdw-editor-main {
    height: 200px !important;
    overflow-y: scroll !important;
}
.donut-inner {
    margin-top: -11em;
    text-align: center;
}
.donut-inner2 {
    margin-top: -3.5em;
    text-align: center;
}

/* SCROLL-BAR */

.container::-webkit-scrollbar {
    display: none;
}
.iframeBox::-webkit-scrollbar {
    display: none;
}

.container::-webkit-scrollbar-thumb {
    background-color: #e6e8f0; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    height: 1px;
    scrollbar-width: thin;
}

/* Handle */

.rec-dot_active {
    /* background-color: #21d8a5 !important;
    box-shadow: 0 0 1px 3px #21d8a5 !important; */
    visibility: hidden;
}
.rec-dot:focus,
.rec-dot:hover {
    /* box-shadow: 0 0 1px 3px var(--blue) !important; */
    visibility: hidden;
}
.rec-dot_active:focus,
.rec-dot_active:hover {
    /* background-color: #21d8a5 !important;
    box-shadow: 0 0 1px 3px #21d8a5 !important; */
    visibility: hidden;
}

.rec-arrow {
    /* background-color: var(--blue) !important;
    transition: all 0.3s ease;
    font-size: 1.1rem !important;
    color: black !important;
    width: 1.7rem !important;
    height: 1.7rem !important;
    min-width: 1.7rem !important;
    line-height: 1.7rem !important; */

    visibility: hidden;
}
button.rec-dot {
    visibility: hidden;
}
/* .rec-arrow:hover:not(:disabled) {
    transform: scale(1.2);
} */
.turnover-date fieldset{
 border: none !important;
}