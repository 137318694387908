.letterbox-1 {
    border: 1px solid #dbd9d9 !important;
    max-height: 600px;
    border-radius: 6px;
    padding: 1em;
    overflow-x: auto;
}

.letterbox-2 {
    border: 1px solid #dbd9d9 !important;
    height: auto;
    border-radius: 6px;
    padding: 1em;
}

.letterbox-3 {
    border: 1px solid #dbd9d9 !important;
    height: 600px;
    border-radius: 6px;
    padding: 1em;
}

.title1 {
    font-weight: bold !important;
    font-size: 0.9em !important;
    color: #7c0e6f !important;
}

.title2 {
    color: #8f95b2 !important;
    font-size: 10px !important;
    margin-top: 0.5em !important;
}

.custom-modallettergen {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1500px;
    height: auto;
}

.editor-class {
    height: 260px !important;
    line-height: 1.2;
}

.editor-class2 {
    height: 480px !important;
}

.footergridletter {
    display: flex;
    margin-top: 1em;
    margin-bottom: -0.5em;
}

.footerleftGrid2 {
    flex-grow: 1;
}

.title-h1 {
    
    color: #7c0e6f !important;
    font-size: 0.8em !important;
    font-weight: bold !important;
}

.title-h2 {
    
    color: #8f95b2 !important;
    font-size: 0.7em !important;
    font-weight: bold !important;
}

.title-end {
    
    color: #8f95b2 !important;
    font-size: 0.7em !important;
    font-style: italic !important;
}

.uploadBox {
    border: 2px dashed #e6e8f0;
}

.upload-title {
    margin-left: 0.2em !important;
    font-size: 0.5em !important;
    
}

.title-left {
    
    color: #000000 !important;
    font-size: 0.9em !important;
    font-weight: bold !important;
}

.formula-box {
    border: 1px solid #f5f5f5 !important;
    height: 45px;
    color: #333333;
    padding: 1em;
    
    font-size: 0.8em !important;
    border-radius: 5px;
    margin-top: 1em;
}

.requestBox {
    color: #ffb400 !important;
    height: 2em !important;
    width: auto !important;
    background-color: #d1e28d1a !important;
    text-transform: capitalize !important;
    font-size: 0.86em !important;
    font-weight: bold !important;
}

.rejectBox {
    
    color: #fd4c06 !important;
    background-color: rgb(241, 169, 169);
    height: 2em !important;
    width: auto !important;
    text-transform: capitalize !important;
    font-size: 0.75em !important;
    font-weight: bold !important;
}

.custom-modalprvwLtrqt {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 700px;
    height: auto;
}

.eye-icon {
    color: #7c0e6f !important;
    cursor: pointer !important;
}

.reject-button {
    font-size: 0.8em !important;
    font-weight: bold !important;
    color: white !important;
    border-radius: 5px !important;
    background-color: #ff5263 !important;
    text-transform: none !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em !important;
    padding-bottom: 0.1em !important;
    border-radius: 0.5em !important;
    height: 3.3em !important;
}

.generateBox {
    color: #21d8a5 !important;

    height: 2em !important;
    width: auto !important;
    background-color: #21d8a51a !important;
    text-transform: capitalize !important;
    font-size: 0.75em !important;
    font-weight: bold !important;
}

.pendingBox {
    color: #ff754c !important;
    height: 2em !important;
    width: auto !important;
    background-color: #fff1ed !important;
    text-transform: capitalize !important;
    font-size: 0.75em !important;
    font-weight: bold !important;
}