.tab-header1 {
    border-radius: 16px 16px 0px 0px;
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px hsla(220, 33%, 98%, 0.161);
    padding-top: 1em;
}

.custom-modalSalaryReport {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1300px;
    height: auto;
}

.modalBankSalaryReport {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 900px;
    height: auto;
}

.custom-configBox {
    border: 1px solid #0000001f;
    height: 435px;
    width: 100%;
    padding-top: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1em;
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: auto;
}

.custom-viewConfig {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 550px;
    height: auto;
}

.btn-addConfig {
    
    font-size: 0.8em;
    background-color: #21d8a51a;
    border-radius: 10px;
    border: 1px dashed #66d4a8 !important;
    text-transform: capitalize;
    height: 35px;
    width: 65px;
    align-items: center;
    justify-content: center;
    display: flex;
    color: #66d4a8;
}

.settlementbox {
    background-color: #fbfbfd;
    height: 6.5em;
    border-radius: 1;
    padding: 1.5em;
    width: 16em;
}

.headersettlementbox {
    background-color: #fbfbfd;
    height: auto;
    border-radius: 10px;
    padding: 1em;
    width: auto;
}

.newStaff {
    
    color: #4caf50;
    font-size: 0.75em;
}

.payrollsetttitle_title1 {
    
    color: #575757;
    font-size: 0.875em;
}

.payrollsetttitle_title2 {
    
    color: #000000;
    font-size: 1.25em;
    font-weight: bolder !important;
}

.payrollsetttitle_title3 {
    
    color: #7c0e6f;
    font-size: 0.875em;
    font-weight: bolder !important;
}

.payrollsetttitle_title4 {
    
    color: #8181a5;
    font-size: 0.875em;
}

.sendUr-Approval {
    
    background-color: #7c0e6f;
    font-size: 0.875em;
    text-transform: capitalize;
}

.custom-generatesett {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: 680px;
}

.custom-modalpayrollcycle {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 550px;
    height: 700px;
}

.custom-modalGovTax1 {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1100px;
}

.custom-modalAssignGovTax1 {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 850px;
}

.export-buttonW {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: #7c0e6f !important;
    padding: 6px 12px;
    border: 1px dashed !important;
    /* line-height: 1.5; */
    background-color: #7c0e6f1a !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-radius: 0.5em;
    /* margin-right:1.5em;
    margin-left:1.5em; */
    height: 3.1em;
}

.boxSug {
    background-color: #fafafa;
    border-radius: 0.2em;
    height: 40px;
    padding: 6px 12px !important;
    margin-top: 0.5em;
}

.titleConfig {
    
    font-size: 0.8em !important;
    color: #0000008a;
}

.titleConfig2 {
    
    font-size: 0.8em !important;
    color: #000000;
    font-weight: bold;
}

.box111 {
    border: 1px solid #0000001f;
    height: 450px !important;
    width: 100% !important;
    padding-top: 1em !important;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
    padding-bottom: 1em !important;
    border-radius: 15px !important;
}

.box2 {
    border: 1px solid #0000001f;
    height: 450px;
    width: 100%;
    padding-top: 1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1em;
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: auto;
}

.MuiDataGrid-columnsContainer {
    direction: 'ltr'
}

.MuiDataGrid-virtualScroller {
    direction: 'ltr',
}