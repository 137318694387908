@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amiri&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --english-font: 'Lato';
  --arabic-font: 'Cairo';
}

@layer components {
  .transition-300 {
    @apply transition-all duration-300 ease-in-out;
  }
  .transition-500 {
    @apply transition-all duration-500 ease-in-out;
  }
  .button {
    @apply flex items-center justify-center uppercase text-xs font-medium h-[40px] px-4 rounded-lg transition-300;
  }
  .primary-btn {
    @apply button bg-primary text-white shadow-md relative hover:bg-primary-light hover:text-primary border border-primary;
  }
  .outline-btn {
    @apply button border border-red-500 text-red-500 hover:shadow-md;
  }
  .bulk-import-btn {
    @apply button text-green-500 bg-green-500 bg-opacity-10 border-dashed border-green-500 border hover:bg-opacity-100 hover:text-white;
  }
  .bulk-import-btn i {
    @apply !text-base;
  }
  .export-btn {
    @apply button text-primary bg-primary-light bg-opacity-10 border-dashed border-primary border gap-x-1 hover:bg-primary hover:text-white;
  }
  .export-btn i {
    @apply !text-base;
  }
  .disabled-btn {
    @apply button bg-gray-200 text-gray-400 cursor-not-allowed;
  }
  .icon-bg {
    @apply relative flex justify-center items-center w-[45px] h-[45px] bg-base-gray rounded-lg;
  }
  .base-modal {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
  }
}

body,
p,
span,
div,
label,
button,
li,
g,
text,
canvas {
  font-family: var(--english-font) !important;
}

/* Font for Arabic */
.arabic-font body,
.arabic-font p,
.arabic-font span,
.arabic-font div,
.arabic-font label,
.arabic-font button,
.arabic-font li,
.arabic-font g,
.arabic-font text,
.arabic-font canvas {
  font-family: var(--arabic-font) !important;
}

.arabic-font .app-modal {
  direction: rtl;
}

.muiltr-1wc848c-MuiFormHelperText-root {
  letter-spacing: 0 !important;
  font-size: 10px !important;
  margin-left: 2px !important;
}

/* Font for .tss-1akey0g-MUIDataTableHeadCell-data */
.tss-1akey0g-MUIDataTableHeadCell-data {
  font-size: 13px !important;
  font-family: var(--english-font) !important;
  text-wrap: nowrap !important;
}

.arabic-font .tss-1akey0g-MUIDataTableHeadCell-data {
  font-family: var(--arabic-font) !important;
}
.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
  background: #f5f5f5 !important;
}

.muiltr-1ygcj2i-MuiTableCell-root {
  white-space: nowrap !important;
}

.muiltr-1ygcj2i-MuiTableCell-root {
  font-weight: bold !important;
}

.tss-1fbujeu-MUIDataTableHeadCell-toolButton {
  margin: 0 !important;
  padding: 0 !important;
}

.tss-1qtl85h-MUIDataTableBodyCell-root
  .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
  font-size: 12px !important;
  font-family: var(--english-font) !important;
  text-wrap: nowrap !important;
}

.arabic-font
  .tss-1qtl85h-MUIDataTableBodyCell-root
  .tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
  font-size: 12px !important;
  font-family: var(--arabic-font) !important;
  text-wrap: nowrap !important;
}

.tss-1cdcmys-MUIDataTable-responsiveBase {
  min-height: 54vh !important;
}

.date--input .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-bottom: 2px !important;
}

.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-bottom: 12px !important;
}

.muirtl-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  top: 50%;
  transform: translateY(-50%);
  padding: 0 5px;
}

.arabic-font .muirtl-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  top: 50%;
  transform: translateY(-50%);
  padding: 0 10px;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
  left: inherit !important;
  right: 1.75rem !important;
  transform-origin: right !important;
}

.arabic-font .custom-rtl label {
  left: unset;
  right: 5px;
}

.arabic-font .apexcharts-legend-series .apexcharts-legend-text {
  margin-right: 5px;
}

.MuiButtonGroup-root {
  z-index: 5 !important;
}

.muiltr-nfmglb-MuiTableCell-root {
  border-bottom: none !important;
}

.hidden-scroll::-webkit-scrollbar {
  width: 0;
}

.hidden-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* scrollbar */
.thin-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.thin-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.thin-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.thin-scroll::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.tss-1cdcmys-MUIDataTable-responsiveBase {
  position: static !important;
}
