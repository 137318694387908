.link-style {
    font-size: 1em;
    margin-right: 3;
    color: #7c0e6f;
}
.sub-title {
    color: #0f266e !important;
    font-size: 0.85em !important;
    font-weight: bold !important;
}
.tab-header {
    border-radius: 16px 16px 0px 0px;
    background: white 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px hsla(220, 33%, 98%, 0.161);
    padding-top: 1em;
}
.main-grid {
    margin-top: 2em;
}
.borderBox {
    border: 1px solid #ebebeb;
    height: 550px;
    width: auto;
    border-radius: 10px;
    padding: 1em;
}
.borderBox2 {
    border: 1px solid #ebebeb;
    height: 500px;
    width: auto;
    border-radius: 10px;
    padding: 1em;
}
.title {
    font-weight: bold;
    font-size: 18px;
}
.grid-1 {
    flex-grow: 1;
}
.title3 {
    font-weight: bold !important;
    font-size: 0.7em !important;
}
.box-list1 {
    width: 8px;
    height: 8px;
    background-color: #3f51b5;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.box-list2 {
    width: 8px;
    height: 8px;
    background-color: #e57373;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.box-list3 {
    width: 8px;
    height: 8px;
    background-color: #CDDC39;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.box-list4 {
    width: 8px;
    height: 8px;
    background-color: #FF4081;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.box-list5 {
    width: 8px;
    height: 8px;
    background-color: #ff7043;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.box-list6 {
    width: 8px;
    height: 8px;
    background-color: #CDDC39;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.box-list7 {
    width: 8px;
    height: 8px;
    background-color: #ff6f00;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.box-list8 {
    width: 8px;
    height: 8px;
    background-color: #2e7d32;
    margin-right: 0.5em;
    margin-top: 0.3em;
}
.grid-space {
    margin-top: 0.2em;
}
.emp-count {
    font-size: 3em;
    font-weight: bold !important;
}
.emp-title {
    font-size: 0.9em !important;
    color: dimgray;
    font-weight: bold !important;
}
.workigHrsModal-Box {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: auto;
}
.title2 {
    font-size: 0.6em !important;

    color: #575757;
}
.present-style {
    font-size: 1em !important;
    color: #4caf50;
    font-weight: bold !important;
}
.absent-style {
    font-size: 1em !important;
    color: #f44336;
    font-weight: bold !important;
}
.weekoff-style {
    font-size: 1em !important;
    color: #e57373;
    font-weight: bold !important;
}
.holiday-style {
    font-size: 1em !important;
    color: #7c0e6f;
    font-weight: bold !important;
}
.leave-style {
    font-size: 1em !important;
    color: #64e693;
    font-weight: bold !important;
}
.unpaid-style {
    font-size: 1em !important;
    color: #6f4891;
    font-weight: bold !important;
}
.halfday-style {
    font-size: 1em !important;
    color: #ffb400;
    font-weight: bold !important;
}
