:root {
  --primary: #7c0e6f;
  --lightprimary: #f2e6f0;
  --greydefault: #757575;
  --greydefault_500: #9e9e9e;
  --greysubtitle: #8f95b2;
  --blackdefault: #000000;
  --darkblue: #081735;
  --defaultsuccess: #4caf50;
  --defaultmisc: #ffb400;
  --defaultinfo: #0d3c61;
  --defaulticon: #575757;
  --primarymain: #7c0e6f;
  --defaultscondary_blue: #0c2372;
  --dashboardcolor: #f5f5fa;
  --title: 16px;
  --subtitle: 14px;
  --header: 18px;
  --fontFamily: Lato, sans-serif;
}
body {
  margin: 0px !important;
}
.login-box {
  width: auto;
  max-width: 26.063em;
  max-height: 30.125em;
  height: auto;
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 20px;
  opacity: 1;
}

.welcome-back {
  font-family: var(--fontFamily);
  color: var(--defaultscondary_blue);
  text-align: center;
  font: normal normal normal 34px/41px Lato;
  letter-spacing: 3.4px;
  opacity: 1;
  padding-top: 40px;
}
.log-in {
  font-family: var(--fontFamily);
  color: #757575;
  padding-bottom: 34px;
}
.swipe-container {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  
  .swipe-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .swipe-button:active {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .swipe-button.dragging {
    transition: none; /* Remove the transition during drag */
  }
  