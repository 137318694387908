.tab-header {
    border-radius: 16px 16px 0px 0px;
    background: #fafafa 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #8f95b229;
}

.settlementbox {
    background-color: #fbfbfd;
    height: 6.5em;
    border-radius: 1;
    padding: 1.5em;
    width: 16em;
}
.headersettlementbox {
    background-color: #fbfbfd;
    height: auto;
    border-radius: 10px;
    padding: 1em;
    width: auto;
}

.newStaff {
    
    color: #4caf50;
    font-size: 0.75em;
}
.payrollsetttitle_title1 {
    
    color: #575757;
    font-size: 0.875em;
}
.payrollsetttitle_title2 {
    
    color: #000000;
    font-size: 1.25em;
    font-weight: bolder !important;
}
.payrollsetttitle_title3 {
    
    color: #7c0e6f;
    font-size: 0.875em;
    font-weight: bolder !important;
}
.payrollsetttitle_title4 {
    
    color: #8181a5;
    font-size: 16px;
}
.sendUr-Approval {
    
    background-color: #7c0e6f;
    font-size: 0.875em;
    text-transform: capitalize;
}
.custom-generatesett {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1000px;
    height: 680px;
}
.custom-modalpayrollcycle {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 550px;
    height: 700px;
}
.custom-modalGovTax1 {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1100px;
}
.custom-modalAssignGovTax1 {
    padding: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 850px;
}
