.ql-toolbar {
  background-color: #f5f5f5;
  border-radius: 10px 10px 0 0;
  direction: ltr;
}

.ql-editor {
  direction: ltr;
  text-align: left;
}


.arabic-font .ql-editor {
  direction: rtl;
  text-align: right;
}

.ql-editor p img {
  display: unset !important;
}

.padding-0 input {
  padding: 0 !important;
}