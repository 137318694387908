.colortext {
    font-size: 0.94em;
    text-align: left;
    font-weight: bold;
    color: #7c0e6f;
}
.textstyle {
    font-weight: bold;
    font-size: 12px;
}
.tss-1akey0g-MUIDataTableHeadCell-data {
    font-size: 0.85em;
    font-weight: bold;
}
.tss-58la38-MUIDataTable-paper {
    border: 0.5px solid #e6e8f0 !important;
    border-radius: 0.5em !important;
}
.custom-modal1 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 980px;
    height: 730px;
}
.export-button2 {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: #7c0e6f !important;
    padding: 6px;
    border: 1px dashed !important;
    /* line-height: 1.5; */
    background-color: #7c0e6f1a !important;

    border-radius: 0.5em;
    /* margin-right:1.5em;
    margin-left:1.5em; */
    height: 2em;
}
.custom-modal-payroll {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 500px;
    height: 500px;
}
.custom-modal-setsalary {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1190px;
    height: 500px;
}
.custom-modal-fnf {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 1350px;
    height: 760px;
}
.custom-modal3 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 980px;
}
.leave-modal {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 980px;
    height: 90vh;
}
.custom-modal2 {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 700px;
    height: 800px;
}

.custom-modalOvertime {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 900px;
    height: 550px;
}
.custom-modalRecu {
    padding: 3em;
    padding-top: 2em;
    border-radius: 1em;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 800px;
    height: 650px;
}
.border-box {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    padding: 1em;
    overflow-y: auto;
}
.border-boxovertime {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    padding: 1em;
}
.border-boxgra {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    padding: 1em;
    max-height: 570px;
    overflow-y: auto;
}
.approvedby-text {
    font-weight: "bold" !important;
    color: black;
    font-size: 1em !important;
}
.leave-box {
    background-color: #f5f5fa66;
    height: 170px;
    border-radius: 0.6em;
    padding: 0.7em;
    display: flex;
    margin-bottom: 1em;
}
.reject-box {
    padding: 3em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 35.9375em;
    height: 21.25em;
}
.input-box3 {
    /* border: 1px solid #0000001A; */
    border-radius: 4px;
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.border-boxAction-payroll {
    background-color: white;
    border-radius: 0.8em;
    border: 1px solid #0000001f;
    height: 43em;
    overflow-y: auto;
}
.input-boxDate {
    border: 1px solid #0000001a;
    border-radius: 4px;
    padding: 10px 6px;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 9em;
}
