.export-button {
    box-shadow: none;
    text-transform: none !important;
    font-size: 0.8em !important;
    color: #7c0e6f !important;
    padding: 6px 12px;
    border: 1px dashed !important;
    line-height: 1.5;
    background-color: #7c0e6f1a !important;
    
    padding-left: 2.5em !important;
    padding-right: 2.5em !important;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-radius: 0.8em;
    margin-right: 1.5em;
    margin-left: 1.5em;
    height: 3.5em;
}
.top {
    margin-left: 1em !important ;
    flex: 1;
}
.border-boxholidayData {
    background-color: white;
    /* border-radius: 0.5em;
    border: 1px solid #0000001f; */
}
.top-right {
    height: 2.8em !important;
    flex-wrap: nowrap !important;
    flex-direction: row;
}
.header-text {
    cursor: pointer;
    
    font-weight: bold;
    font-size: 12px;
    text-align: left;
    padding: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.text {
    
    font-size: 12px;
    text-align: left;
}
.bolder-text {
    
    font-size: 12px;
    text-align: left;
    font-weight: bold;
}
.active-text {
    
    font-size: 12px;
    color: #21d8a5;
    font-weight: bold;
}
.inactive-text {
    
    font-size: 12px;
    color: red;
    font-weight: bold;
}
.action-text {
    
    font-size: 12px;
    color: #7c0e6f;
    font-weight: bold;
    cursor: pointer;
}
.input-box {
    border: 1px solid #00000041;
    border-radius: 4px;
    padding: 14px 6px;
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.select {
    background-color: white;
    text-align: end;
}
.input-box-title {
    flex: 1;
    color: #00000099;
    font: normal normal normal 0.85em;
    margin-left: 0.8em !important;
}
.to {
    color: #8181a5;
    font: italic normal medium;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
}
.holidays-type-box {
    padding: 3em;
    border-radius: 1em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 525px;
    height: "auto";
}
.approval-label {
    flex: 1;
    color: #00000099;
    font: normal normal normal;
    font-size: 0.85em !important;
}
.input-box-leave-type {
    border: 1px solid #0000001a;
    border-radius: 4px;
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
